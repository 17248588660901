<template>
  <v-card flat color="grey lighten-3" class="d-flex justify-space-between mx-1">
    <div class="overflow-x-auto d-flex justify-start pr-3">
      <div v-for="(cat, i) in getCategories" :key="i">
        <div class="d-flex justify-start mb-2">
          <product-card
            v-for="item in cat.products"
            :key="item.id"
            :item="item"
            :favored="true"
            :show-name="false"
            :show-description="false"
            class="ml-3 my-1"
          />
            <!-- :favorite-button="false" -->
        </div>
      </div>
    </div>
    <div class="d-flex justify-start" >
      <v-divider vertical />
      <v-btn x-large icon depressed tile style="margin: auto 10px;" @click="$router.push({ name: 'favorites', params: { back: { name: $route.name }}})">
        <v-icon x-large>mdi-dots-horizontal</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductCard from "@/views/stores/ProductCard";
import SectionHeader from "@/common/components/SectionHeader";
import FullView from "@/common/layouts/FullView";
import BookmarkTabs from "@/common/components/BookmarkTabs";
import _ from "lodash";

export default {
  name: "Favorites",
  components: { ProductCard, SectionHeader, FullView, BookmarkTabs },
  data() {
    return {
      tab: null,
    };
  },
  watch: {
    getCategories: function(n) {
      if (this.notEmptyArray(n)) {
        this.tab = "cat-" + n[0].id;
      }
    },
  },
  mounted() {
    this.loadFavoritesByParams({ storeId: this.getStoreIdParam });
    const { id:customerId } = this.$getUserInfo;
    this.setReactivityParams({
      resource: "FAVORITES",
      scope: "store/customer",
      key: `${this.getStoreIdParam}/${customerId}`,
    });

    this.$sub("reactivity/FAVORITES", (payload) => {
      this.loading = true;
      if(payload.mode !== "Delete") {
        this.reloadFavoritesByParams({ storeId: this.getStoreIdParam }).then(() => (this.loading = false));
      } else {
        this.removeFavoritesFromState(payload.product).then(() => (this.loading = false));
      }
      console.log("reactivity/FAVORITES:", payload);
    });

    // reactivity params for PRODUCTS is already added in parent component OpenStore
    this.$sub("reactivity/PRODUCTS", (payload) => {
      this.loading = true;
      if(payload.mode === "Delete") {
        this.loadFavoritesByParams({ storeId: this.getStoreIdParam }).then(() => (this.loading = false));
      }
      console.log("reactivity/FAVORITES:", payload);
    });
  },
  beforeDestroy() {
    this.$unsubAll();
    this.clearReactivityParams();
  },
  computed: {
    ...mapGetters("favorites", ["getFavorites"]),

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getCategories() {
      const groups = _.groupBy(this.getFavorites, (o) => {
        if (o.category && o.category.section) {
          return `${o.category.section.name} :: ${o.category.name}`;
        } else {
          return "Uncategorized";
        }
      });

      let cats = [];
      for (let p in groups) {
        cats.push({
          name: p,
          id: groups[p][0].category.id,
          products: groups[p],
        });
      }

      return cats;
    },
  },
  methods: {
    ...mapActions("favorites", ["loadFavoritesByParams", "reloadFavoritesByParams", "removeFavoritesFromState"]),
    ...mapActions("ui", ["setReactivityParams", "clearReactivityParams"]),

    swipe(id, dir) {
      const cats = this.getCategories;
      const current = cats.find((f) => f.id === id);
      const index = cats.indexOf(current);
      if (dir === "right" && index > 0) {
        this.goTo(cats[index - 1].id);
      } else if (dir === "left" && index < cats.length - 1) {
        this.goTo(cats[index + 1].id);
      }
    },
    goTo(id) {
      this.$vuetify.goTo("#cat-" + id, {
        offset: -40,
        container: this.$refs.viewFrame,
      });
      this.tab = "cat-" + id;
    },
  },
};
</script>
